import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskNewPatientSearchResultLayout.styles';
import { KioskNewPatientSearchResultMain } from '..';
import { KioskWelcomeHeader, KioskWelcomeFooter, KioskIdelScreen } from '..';
export class KioskNewPatientSearchResultLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: 'Enrollment'
  };

  render() {
    const { classes } = this.props;
    var customFooterBottom;
    if (window.screen.width >= 1440) {
      customFooterBottom = '-25%';
    } else {
      customFooterBottom = '0%';
    }

    return (
      <div className={classNames(classes.container)}>
        <KioskWelcomeHeader />
        <KioskNewPatientSearchResultMain />
        <KioskWelcomeFooter customFooterBottom={customFooterBottom} />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  KioskNewPatientSearchResultLayout
);
