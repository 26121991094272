import { Provider } from 'react-redux';

import { persistStore, autoRehydrate } from 'redux-persist-immutable';
import createEncryptor from 'redux-persist-transform-encrypt';
import createCompressor from 'redux-persist-transform-compress';
// import { asyncSessionStorage } from 'redux-persist/storages';

import rootSaga from '../sagas';
import rootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router/immutable';

import Immutable from 'immutable';
import { ConnectedRouter as Router } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore
} from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { getState } from 'loadable-components';
// import { createLogger } from 'redux-logger';

const encryptor = createEncryptor({
  secretKey: 'my-super-secret-key',
  onError: function (error) {
    // Handle the error.
  }
});

// const logger = createLogger({
//   stateTransformer: state => state && state.toJS(),
//   predicate: (getState, action) => {
//     return action.type !== 'UPDATE_POLLING_STATS';
//   }
//   // ...options
// });

// https://github.com/rt2zz/redux-persist-transform-compress
const compressor = createCompressor();

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const history = createBrowserHistory();
const initialState = Immutable.Map({
  auth: {
    isAuthenticated: false
  }
});

const middlewares = [];
middlewares.push(routerMiddleware(history));
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger);
}

const store = createStore(
  connectRouter(history)(rootReducer), // new root reducer with router state
  initialState,
  compose(composeEnhancers(applyMiddleware(...middlewares), autoRehydrate()))
);

const persistor = persistStore(store, {
  //storage: asyncSessionStorage, // Comment this line to store it in localstorage.
  transforms: [encryptor, compressor],
  blacklist: ['router', 'persistance']
  // whitelist: ['preferences']
});

sagaMiddleware.run(rootSaga);

window.snapSaveState = () => getState();

export {
  Provider,
  Router,
  store,
  history,
  connectRouter,
  rootReducer,
  persistor
};
