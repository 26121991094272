// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SimpleStartKioskWelcomeScreenLayout.styles';
import {
  SimpleStartKioskWelcomeHeader,
  SimpleStartKioskWelcomeFooter,
  SimpleStartKioskWelcomeScreenMain,
  KioskIdelScreen
} from '..';

export class SimpleStartKioskWelcomeScreenLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.container)}>
        <SimpleStartKioskWelcomeHeader />
        <SimpleStartKioskWelcomeScreenMain />
        <SimpleStartKioskWelcomeFooter customFooterBottom="0%" />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  SimpleStartKioskWelcomeScreenLayout
);
