const styles = (theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
      // height: '8%'
      //position: 'absolute',
    },
    NeedHelp: {
      color: '#aaa',
      display: 'grid',
      padding: '10px',
      fontSize: '0.90rem',
      float: 'left',
      width: '33.3333%',
      textAlign: 'left'
    },
    logo: {
      color: '#aaa',
      display: 'grid',
      padding: '10px',
      fontSize: '0.90rem',
      float: 'left',
      width: '33.3333%',
      justifyContent: 'center'
    },
    exit: {
      color: '#aaa',
      display: 'grid',
      padding: '10px',
      fontSize: '0.90rem',
      float: 'left',
      width: '33.3333%',
      textAlign: 'right'
    },
    labelClass: {
      padding: '0px 35px'
    },
    spanLink: {
      cursor: 'pointer'
    },
    ExitlabelClass: {
      padding: '0px 35px 0px 0px'
    },
    dialogBackground: {
      background: 'transperent'
      //background: 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)'
    },
    dialogContentColor: {
      //color: '#387371',
      color: '#77818D',
      textAlign: 'center',
      fontSize: '1.9em',
      fontWeight: '500'
    },
    alignButton: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonColor: {
      //color: '#387371',
      color: 'black',
      fontSize: '1.9em'
    },
    imgProperty: {
      // opacity: '0.5'
    },
    '@media only screen and (min-width: 1440px) and (orientation:landscape)': {
      logo: {
        color: '#aaa',
        display: 'grid',
        padding: '10px',
        fontSize: '0.90rem',
        float: 'left',
        width: '33.3333%',
        justifyContent: 'center'
      },
      imgProperty: {
        height: '70px'
      }
    }
  };
};

export default styles;
