import Amplify from 'aws-amplify';
// import { Auth } from 'aws-amplify';

export const ConfigureAmplify = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

      // REQUIRED - Amazon Cognito Region
      // region: 'XX-XXXX-X',
      region: process.env.REACT_APP_CG_REGION,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      // userPoolId: 'XX-XXXX-X_abcd1234',
      userPoolId: process.env.REACT_APP_CG_USERPOOLID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      // userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
      userPoolWebClientId: process.env.REACT_APP_CG_WEBCLIENTID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: false,
      mandatorySignIn: true,

      // OPTIONAL - Configuration for cookie storage
      // cookieStorage: {
      // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //     domain: '.yourdomain.com',
      // // OPTIONAL - Cookie path
      //     path: '/',
      // // OPTIONAL - Cookie expiration in days
      //     expires: 365,
      // // OPTIONAL - Cookie secure flag
      //     secure: true
      // },

      // OPTIONAL - customized storage object
      // storage: new MyStorage(),
      storage: sessionStorage // Comment this line to default to localStorage, in future need to use LocalForage with encryption

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  });
};

// export const GetCurrentUser = () => {
//   return Auth.currentAuthenticatedUser();
// }
