import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './ErrorBoundry.style';
import Button from '@material-ui/core/Button';
const { writePickupLogsToFile } = require('../Common/SyncAPIs');

class ErrorBoundarys extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    let log = {
      message: `Error Boundry : Error ${error} - Info ${info}`
    };
    writePickupLogsToFile(log);
    console.error(error + '' + info);
  }

  handleUhOhClick = () => {
    this.setState({ hasError: false });
    this.props.history.push('/');
  };

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <div className={classNames(classes.root)}>
          <div className={classNames(classes.dialogContentColor)}>
            <h1>Uh Oh! Can we try that again?</h1>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              variant="contained"
              className={classNames(classes.primaryButton)}
              onClick={this.handleUhOhClick}
            >
              Start Over
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  const kiosk =
    state.get('kiosk') && state.get('kiosk').toJS && state.get('kiosk').toJS();
  return {
    ...kiosk
  };
};

const ErrorBoundarysContainer = connect(mapStateToProps)(ErrorBoundarys);

export default withStyles(styles, { withTheme: true })(
  withRouter(ErrorBoundarysContainer)
);
