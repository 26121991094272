import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Card, Grid, withStyles } from '@material-ui/core';
import {
  ExpressCaptureDL,
  ExpressConfirmLegalDocs,
  ExpressConfirmPickup,
  ExpressConsultation,
  ExpressOpenBin,
  ExpressThankYou,
  ExpressVerifyDOB,
  ExpressWelcome
} from './components';
import Footer from './components/Common/Footer';
import Header from './components/Common/Header';

const styles = () => ({
  root__container: {
    width: '100vw',
    backgroundColor: '#E6E7E8',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  },
  header__footer__container: {
    height: '6%',
    textAlign: 'center',
    alignItems: 'center'
  },
  main__container: {
    height: '80%'
  },
  main__card: {
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    height: '100%',
    boxShadow: '0 0 30px rgb(0 0 0 / 20%)'
  }
});

const ExpressUI = ({ classes, box, history, match }) => {
  return (
    <div className={classes.root__container}>
      <Header box={box} />
      <Grid
        container
        className={classes.main__container}
        justifyContent="center"
      >
        <Grid item xs={10} style={{ height: '100%' }}>
          <Card className={classes.main__card}>
            <Route path={`${match.path}/Welcome`} component={ExpressWelcome} />
            <Route
              path={`${match.path}/VerifyDOB`}
              component={ExpressVerifyDOB}
            />
            <Route
              path={`${match.path}/ConfirmLegalDocs`}
              component={ExpressConfirmLegalDocs}
            />
            <Route
              path={`${match.path}/ConfirmPickup`}
              component={ExpressConfirmPickup}
            />
            <Route
              path={`${match.path}/Consultation`}
              component={ExpressConsultation}
            />
            <Route
              path={`${match.path}/CaptureDL`}
              component={ExpressCaptureDL}
            />
            <Route path={`${match.path}/OpenBin`} component={ExpressOpenBin} />
            <Route
              path={`${match.path}/ThankYou`}
              component={ExpressThankYou}
            />
          </Card>
        </Grid>
      </Grid>
      <Footer box={box} history={history} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(withStyles(styles)(ExpressUI));
