// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import styles from './AdminMaintenanceMain.styles';
import { Paper } from '@material-ui/core';

import AdminMaintenanceFormContainer from './AdminMaintenanceFormContainer';

export class AdminMaintenanceMain extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  handleBack = () => {
    this.props.history.push('/RearStock/AdminPanel');
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classNames(classes.container)}>
          {/* <AdminMaintenanceFormControls handleBack={this.handleBack} /> */}
          <AdminMaintenanceFormContainer />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(AdminMaintenanceMain)
);
