import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { LOGIN_REQUEST, LOGIN_FAILURE } from './AdminLoginActions';

export const login = (data, formActions, context) => ({
  id: uuidv4(),
  datetime: DateTime.local(), // ISO 8601 Format
  type: LOGIN_REQUEST,
  context: {
    formActions,
    ...context
  }, // If anything outside of auth needs to be passed to reducer, pass it in context
  payload: {
    ...data
  }
});

export const onForgotPasswordRequest = (data) => ({
  id: uuidv4(),
  datetime: DateTime.local(), // ISO 8601 Format
  type: 'AUTH/FORGOT_PASSWORD_REQUEST',
  context: {}, // If anything outside of auth needs to be passed to reducer, pass it in context
  payload: { ...data }
});

export const onForgotPasswordSubmit = (username, code, newpassword) => ({
  id: uuidv4(),
  datetime: DateTime.local(), // ISO 8601 Format
  type: 'AUTH/SUBMIT_VERIFICATION_CODE',
  // context: { notify }, // If anything outside of auth needs to be passed to reducer, pass it in context
  payload: { username, code, newpassword }
});

export const onRequestSignIn = (username) => ({
  id: uuidv4(),
  datetime: DateTime.local(), // ISO 8601 Format
  type: LOGIN_FAILURE,
  // context: { notify }, // If anything outside of auth needs to be passed to reducer, pass it in context
  payload: { username }
});
