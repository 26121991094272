import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskForgotPUCodeLayout.styles';
import {
  KioskFooter,
  KioskHeader,
  KioskForgotPUCodeMain,
  KioskIdelScreen
} from '..';
export class KioskForgotPUCodeLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.container)}>
        <KioskHeader />
        <KioskForgotPUCodeMain />
        <KioskFooter customFooterBottom="0%" isLanguageBarAllowd={true} />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(KioskForgotPUCodeLayout);
