import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskConfimPickupLayout.styles';
// import { Paper } from '@material-ui/core';
import {
  KioskFooter,
  KioskHeader,
  KioskConfimPickupMain,
  KioskIdelScreen
} from '..';
import { connect } from 'react-redux';
import * as KioskConfimPickupActionCreator from './KioskConfimPickupActionCreator';

export class KioskConfimPickupLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  render() {
    const { classes, paymentConfig } = this.props;
    var customFooterBottom;
    if (window.screen.width === 1440 || window.screen.width === 1920) {
      customFooterBottom = '-25%';
    } else {
      customFooterBottom = '0%';
    }
    return (
      <div className={classNames(classes.container)}>
        <KioskHeader />
        <KioskConfimPickupMain />
        <KioskFooter
          customFooterBottom={customFooterBottom}
          isLanguageBarAllowd={true}
        />
        {!Boolean(paymentConfig && paymentConfig['isPaymentStarted']) && (
          <KioskIdelScreen />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return {
    ...kiosk
  };
};

const mapDispatchToProps = {
  ...KioskConfimPickupActionCreator
};

const KioskConfirmPickupLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(KioskConfimPickupLayout);

export default withStyles(styles, { withTheme: true })(
  KioskConfirmPickupLayout
);
