import AWS from 'aws-sdk';
import AWSconfig from './awsConfig';

export default function sendAWSEmail(config) {
  AWS.config.update({
    accessKeyId: AWSconfig.accessKeyId,
    secretAccessKey: AWSconfig.secretAccessKey,
    region: AWSconfig.region
  });

  const ses = new AWS.SES({
    region: AWSconfig.region
  });
  const p = new Promise((res, rej) => {
    if (!config.emailId) {
      rej('Missing user email or message content');
    } else {
      const params = createInquiryParamsConfig(config);
      // console.log('Sending email with attached params!')
      // console.log(AWS.config.credentials)

      const sendEmail = ses.sendEmail(params).promise();

      sendEmail
        .then((data) => {
          console.log('email submitted to SES', data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });
  return p;
}

function createInquiryParamsConfig(config) {
  const sbjData =
    config.isDisplay === 'none'
      ? `Your order has been Picked up`
      : 'Your order is ready for pick up';

  const params = {
    Destination: {
      BccAddresses: ['vishal@ilocalbox.com'],
      // CcAddresses: [],
      ToAddresses: [config.emailId]
    },
    Message: {
      Body: {
        Html: {
          Data: generateHTMLInquiryEmail(config),
          Charset: 'UTF-8'
        }
      },
      Subject: {
        Data: sbjData,
        Charset: 'UTF-8'
      }
    },
    Source: AWSconfig.source,
    //  ReplyToAddresses: [ 'ayaz.belim@epistic.net' ],
    ReplyToAddresses: ['golocalrx@ilocalbox.com'],
    ReturnPath: AWSconfig.source
  };
  return params;
}

function generateHTMLInquiryEmail(config) {
  const headerContent =
    config.isDisplay === 'none'
      ? `Your order has been Picked up!`
      : 'Your order is ready for pick up!';
  const pickupCotent =
    config.isDisplay === 'none'
      ? // ? `Your order ${config.orders[0].orderId} has been Picked up!`
        `Your order has been Picked up!`
      : 'Your order is ready for pick up!';

  let adressLine1new = '';
  let adressLine2new = '';
  let citynew = '';
  let statenew = '';
  let companyContactnew = '';

  adressLine1new =
    config.pharmAddress.adressLine1 !== undefined &&
    config.pharmAddress.adressLine1 !== null &&
    config.pharmAddress.adressLine1 !== 'null' &&
    config.pharmAddress.adressLine1 !== ''
      ? config.pharmAddress.adressLine1
      : '';
  adressLine2new =
    config.pharmAddress.adressLine2 !== undefined &&
    config.pharmAddress.adressLine2 !== null &&
    config.pharmAddress.adressLine2 !== '' &&
    config.pharmAddress.adressLine2 !== 'null'
      ? config.pharmAddress.adressLine2
      : '';
  companyContactnew =
    config.pharmAddress.companyContact !== undefined &&
    config.pharmAddress.companyContact !== null &&
    config.pharmAddress.companyContact !== '' &&
    config.pharmAddress.companyContact !== 'null'
      ? config.pharmAddress.companyContact
      : '';
  citynew =
    config.pharmAddress.city !== undefined &&
    config.pharmAddress.city !== null &&
    config.pharmAddress.city !== '' &&
    config.pharmAddress.city !== 'null'
      ? config.pharmAddress.city
      : '';
  statenew =
    config.pharmAddress.state !== undefined &&
    config.pharmAddress.state !== null &&
    config.pharmAddress.state !== '' &&
    config.pharmAddress.state !== 'null'
      ? config.pharmAddress.state
      : '';

  return `
    <!doctype html>
    <html>
    <head>
    <meta charset='utf-8'>
    <meta name='format-detection' content='telephone=no'>
    <meta name='viewport' content='width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=no;'>
    <meta http-equiv='X-UA-Compatible' content='IE=9; IE=8; IE=7; IE=EDGE' />
    <title>Pick Up Notification | Template | iLocal Box</title>
    <style type='text/css'></style>
    </head>
    
    <body bgcolor='#F0F6F2' style='margin: 0;min-width: 100%;height: 100%;padding:0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
    <table align='center' height='100%' valign='top' width='100%' style='mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
      <tr>
        <td bgcolor='#F0F6F2' align='center' height='100%' valign='top' width='100%' style='mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
          <table 
        align='center' 
        border='0' 
        cellpadding='0' 
        cellspacing='0' 
        width='600' 
        style='max-width: 600px;border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;'>
            <tr>
              <td align='center' valign='top' width='100%' style='mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
          <table 
            border='0' 
            cellpadding='0' 
            cellspacing='0' 
            width='100%' 
            style='min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                  <tr>
                    <td 
              id='pre-header' 
              valign='top' 
              style='padding-right: 15px;padding-left: 15px;padding-top:20px;padding-bottom:20px;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
              <table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                        <tr>
                          <td align='left' valign='top' width='255' style='max-width:255px; padding-right: 0;padding-left: 0;padding-top:0;padding-bottom: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                              <tr>
                                <td valign='top' style='padding-right: 15px;padding-left: 15px;padding-top:0;padding-bottom: 0;text-align: left;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                    <!-- Partner Branding: Start -->
                    <img src=${
                      config.pharmAddress.picture_url
                    } alt='' width='174' style='max-width:75px; margin:0; padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;' align='left' border='0'>
                      <!-- Partner Branding: End -->
                    </td>
                              </tr>
                            </table></td>
                          <td align='left' valign='top' width='255' style='max-width:255px; padding-right: 0;padding-left: 0;padding-top:0;padding-bottom: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                              <tr>
                                <td valign='top' style='padding-right:15px;padding-left: 15px;padding-top:0;padding-bottom: 0;text-align: right;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                    <!-- Message: Start -->
                    <p style='font-family: Helvetica, Arial, sans-serif; font-size:14px; line-height:18px; font-weight: normal; text-align: right; color:#819695; margin:0;margin-top: 15%; text-transform: uppercase;'> Pick Up Notification</p>
                      <!-- Message: End -->
                    </td>
                              </tr>
                            </table></td>
                        </tr>
                      </table></td>
                  </tr>
            <tr>
              <td id='content' bgcolor='#FFFFFF' align='center' valign='top' width='100%' style='mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;' align='left' border='0' cellpadding='0' cellspacing='0' width='100%'>   				
               <tr>						
                <td align='center' valign='top' style='padding-top: 0;padding-bottom: 0;padding-right: 0px;padding-left: 0px;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                  <table style='width:100%;margin-top:3%;'>
                    <tr>
                      <td style='text-align:center;width:33.3333334%;'>
                        <table style='width:100%; display:${config.isDisplay}'>
                          <tr>
                            <td style='height:100px;'>
                              <img style='height:100%;' src=${config.qrCode} />										
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p style='font-family: Helvetica, Arial, sans-serif;font-size:18px;line-height: 0; font-weight: normal; text-align: center; color:#4CA585; margin:0;'><b>${
                                config.pickupcode
                              }</b></p>
                            </td>
                          </tr>
                        </table>
                        
                      </td>
                      <td style='text-align:center;width:33.3333334%'>
                        <img src='https://s3.amazonaws.com/rpx-dev-ext-images-qr/header-ilocalbox-default.png' alt='iLocalBox' style='margin:0; padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;' align='center' border='0'>
                      </td>
                      <td style='text-align:center;width:33.3333334%'>
                        &nbsp;
                      </td>
                    </tr>
                  </table>
                </td>
                        </tr>
              
              <tr >
                    <td align='center' valign='top' width='100%' style='padding-top:0;padding-right: 0;padding-bottom: 0;padding-left: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;' align='left' border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td align='center' valign='top' style='padding-right: 15px;padding-left: 15px; mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                              <tr>
                                <td valign='top' style='padding-right: 15px;padding-left: 15px;padding-top:0;padding-bottom:15px;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                    <!-- Main Headline: Start -->
                    
                    <h1 style='font-family: Helvetica, Arial, sans-serif;font-size:36px;line-height: 40px; font-weight: normal; text-align: center; color:#4CA585; margin:0;'>${headerContent}</h1>
                    
                      <!-- Main Headline: End -->
                    </td>
                              </tr>
                            </table></td>
                        </tr>
                      </table></td>
                  </tr>
              <tr>
                    <td align='center' valign='top' width='100%' style='padding-top:0;padding-right: 0;padding-bottom: 0;padding-left: 10;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;' align='left' border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td align='center' valign='top' style='padding-right: 15px;padding-left: 15px; mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                  <!-- Content Area: Start -->
                              <tr>
                                <td valign='top' style='padding-right: 15px;padding-left: 15px;padding-top:15px;padding-bottom:15px;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                    
                    <p style='font-family: Helvetica, Arial, sans-serif;font-size:14px;line-height: 18px; font-weight: normal; text-align: left; color:#819695; margin:0;margin-bottom: 18px;'>Hello ${config.firstName.substring(
                      0,
                      1
                    )}${'.'} ${config.lastName.substring(0, 3)},</p>
                    <p style='font-family: Helvetica, Arial, sans-serif;font-size:14px;line-height: 18px; font-weight: normal; text-align: left; color:#819695; margin:0;'>${pickupCotent}</p>
    
                    </td>
                              </tr>
        
                  <tr style='display: ${config.isDisplay}'>
                                <td valign='top' style='padding-right: 15px;padding-left: 15px;padding-top:15px;padding-bottom:15px;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                    <table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                      <tr>
                        <td bgcolor='#D7EDE7' valign='top' style='padding-right: 0;padding-left: 0;padding-top:0;padding-bottom:0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                          <table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                            <tr>
                              <td valign='top' width='70' style='padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%; width: 60%;'><p style='font-family: Helvetica, Arial, sans-serif;font-size:14px;line-height: 18px; font-weight: normal; text-align: left; color:#819695; margin:0;'><strong>Rx #</strong></p></td>
                              <td valign='top' width='160' style='padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><p style='font-family: Helvetica, Arial, sans-serif;font-size:14px;line-height: 18px; font-weight: normal; text-align: left; color:#819695; margin:0;'><strong>Qty</strong></p></td>
                              <td valign='top' width='70' style='padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><p style='font-family: Helvetica, Arial, sans-serif;font-size:14px;line-height: 18px; font-weight: normal; text-align: left; color:#819695; margin:0;'><strong>Amount</strong></p></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      ${config.orderHtml}
                      ${config.orderTotalHtml}
                    </table>
                    <table border='0' cellpadding='0' cellspacing='0' width='100%' style='margin-top:2%;max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                      <tr>
                         ${config.orderLocation}
                        <td style='width:40%'>
                          &nbsp;
                        </td>
                      </tr>
                    </table>
                    </td>
                              </tr>
                  <tr>
                                <td valign='top' style='padding-right: 15px;padding-left: 15px;padding-top:15px;padding-bottom:30px;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                    <p style='font-family: Helvetica, Arial, sans-serif;font-size:14px;line-height: 18px; font-weight: normal; text-align: left; color:#819695; margin:0;'>If you have any questions or concerns contact us at <a href='mailto:support@ilocalbox.com'  href='${
                      config.pharmAddress.emailId
                    }' style='color:#819695;'>support@ilocalbox.com</a>.</p>
                    
                    </td>
                              </tr>
                    <!-- Content Area: End -->
                            </table></td>
                        </tr>
                      </table></td>
                  </tr>
              
              <tr>
                    <td align='center' valign='top' width='100%' style='padding-top:0;padding-right: 0;padding-bottom: 0;padding-left: 10;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;' align='left' border='0' cellpadding='0' cellspacing='0' width='100%'>
                        <tr>
                          <td align='center' valign='top' style='padding-right: 0;padding-left: 0; mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                               <tr>
                          <td align='center' valign='top' style='padding-top: 0;padding-bottom: 0;padding-right: 0px;padding-left: 0px;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                   <img src='https://s3.amazonaws.com/rpx-dev-ext-images-qr/footer-ilocalbox-gradient-bar.jpg' alt='' width='600' style='max-width:600px; margin:0; padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;' align='center' border='0'>
                
                </td>
                        </tr>
                            </table></td>
                        </tr>
                      </table></td>
                  </tr>
                </table></td>
            </tr>
           <tr>
           <td id='footer' align='center' valign='top' width='100%' style='mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;' align='left' border='0' cellpadding='0' cellspacing='0' width='100%'>
           <tr>
             <td align='center' valign='top' width='100%' style='padding-top:15px;padding-right: 15px;padding-bottom: 30px;padding-left: 15px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;' align='left' border='0' cellpadding='0' cellspacing='0' width='100%'>
                 <tr>
                   <td align='left' valign='top' width='350' style='max-width: 350px; mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                       <tr>
                         <td valign='top' style='padding-right: 15px;padding-left: 15px;padding-top:0;padding-bottom: 0;text-align: left;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
             <!-- Footer iLocal Box Copyright: Start -->
             <p style='font-family: Helvetica, Arial, sans-serif;font-size: 11px;line-height: 14px; font-weight: normal; text-align: left; color:#819695; margin:0; margin-bottom: 18px;'>&copy; iLocal Box, </br> All Rights Reserved</p>
             <!-- Footer iLocal Box Copyright: End -->
             <!-- Footer Partner Info: Start -->
                           <p style='font-family: Helvetica, Arial, sans-serif;font-size: 11px;line-height: 14px; font-weight: normal; text-align: left; color:#819695; margin:0; margin-bottom: 18px'>&copy; ${
                             config.pharmAddress.companyName
                           } ${adressLine1new}. ${citynew}  ${statenew} All rights reserved. </p>
                           <p style='font-family: Helvetica, Arial, sans-serif;font-size: 11px;line-height: 14px; font-weight: normal; text-align: left; color:#819695; margin:0; margin-bottom: 18px;'><strong>Contact Us:</strong><br>
                             ${companyContactnew}</br>
                             ${adressLine1new}<br>
                             ${adressLine2new}<br>
                             </p>
                           </td>
             <!-- Footer Partner Info: End -->
                       </tr>
                     </table></td>
                   <td align='left' valign='top' width='160' style='max-width: 160px; mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'><table border='0' cellpadding='0' cellspacing='0' width='100%' style='max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
                       <tr>
                         <td valign='top' style='padding-right: 15px;padding-left: 15px;padding-top: 0;padding-bottom: 0;text-align: left;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;'>
             <!-- Footer Powered by iLocal Box: Start -->
             <p style='font-family: Helvetica, Arial, sans-serif;font-size: 11px;line-height: 14px; font-weight: normal; text-align: left; color:#819695; margin:0;margin-bottom: 5px;'><em>Powered by:</em>
                           <img src='https://s3.amazonaws.com/rpx-dev-ext-images-qr/logo-ilocalbox.png' alt='iLocal Box' width='50' height='10' style='max-width: 50px; margin:0; padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;' align='center' border='0'></p>
             <!-- Footer Powered by iLocal Box: End -->
             </td>
                       </tr>
                     </table></td>
                 </tr>
               </table></td>
           </tr>
         </table></td>
     </tr>  
         </table></td>
     </tr>
   </table>
   
   <!--[if gte mso 9]>
 </td>
        </tr>
        </table>
        <![endif]--></td>
      </tr>
    </table>
    </body>
    </html>
    `;
}
