import { connect } from 'react-redux';
import AdminMaintenanceForm from './AdminMaintenanceForm';

import * as AdminMaintenanceActionCreator from './AdminMaintenanceActionCreator';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const mapDispatchToProps = {
  ...AdminMaintenanceActionCreator
};

const AdminMaintenanceFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMaintenanceForm);

export default AdminMaintenanceFormContainer;
