// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { closeApplication } from '../../../helpers/board';
import styles from './AdminPanelFormControls.styles';
import Image1 from './AdminPanelImages/1.png';
import Image2 from './AdminPanelImages/2.png';
import Image3 from './AdminPanelImages/3.png';
import Image4 from './AdminPanelImages/4.png';
import tools from './AdminPanelImages/maintenance.png';
import exit from './AdminPanelImages/exit.svg';

export class AdminPanelFormControls extends Component {
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.container)}>
        {/*  =========================   For Rear Stock Screen   ========================= */}
        <div className={classNames(classes.navContainer)}>
          <div className={classNames(classes.navItem)}>
            <Link to="/RearStock/AdminStock">
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={Image1}
                  alt="Stock"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </Link>
            <p className={classNames(classes.navItemText)}>Stock</p>
          </div>

          <div className={classNames(classes.navItem)}>
            <Link to="/RearStock/AdminKioskVerify">
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={Image2}
                  alt="Verify"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </Link>
            <p className={classNames(classes.navItemText)}>Verify</p>
          </div>

          <div className={classNames(classes.navItem)}>
            <Link to="/RearStock/AdminMaintenance">
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={tools}
                  alt="Maintenance"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </Link>
            <p className={classNames(classes.navItemText)}>Maintenance</p>
          </div>

          <div className={classNames(classes.navItem)}>
            <Link to="/RearStock/AdminRemove">
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={Image4}
                  alt="Remove"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </Link>
            <p className={classNames(classes.navItemText)}>Remove</p>
          </div>

          <div className={classNames(classes.navItem)}>
            <Link to="/RearStock/AdminSettings">
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={Image3}
                  alt="Settings"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </Link>
            <p className={classNames(classes.navItemText)}>Settings</p>
          </div>

          <div className={classNames(classes.navItem)}>
            <div
              className={classNames(classes.navItemTop)}
              onClick={closeApplication}
            >
              <img
                src={exit}
                alt="Exit"
                className={classNames(classes.navItemTopImg)}
              />
            </div>
            <p className={classNames(classes.navItemText)}>Exit</p>
          </div>
        </div>

        {/*  =========================   For Kiosk Screen   ========================= */}

        <div className={classNames(classes.flexContainer)}>
          <Link to="/RearStock/AdminStock">
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={Image1}
                alt="Stock"
              />
            </div>
          </Link>
          <Link to="/RearStock/AdminRemove">
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={Image4}
                alt="Remove"
              />
            </div>
          </Link>
        </div>

        <div className={classNames(classes.flexContainer)}>
          <div className={classNames(classes.flexTemText)}>Stock</div>
          <div className={classNames(classes.flexTemText)}>Remove</div>
        </div>

        <div className={classNames(classes.flexContainer)}>
          <Link to="/RearStock/AdminKioskVerify">
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={Image2}
                alt="Verify"
              />
            </div>
          </Link>
          <Link to="/RearStock/AdminSettings">
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={Image3}
                alt="Settings"
              />
            </div>
          </Link>
        </div>

        <div className={classNames(classes.flexContainer)}>
          <div className={classNames(classes.flexTemText)}>Verify</div>
          <div className={classNames(classes.flexTemText)}>Settings</div>
        </div>

        <div className={classNames(classes.flexContainer)}>
          <Link to="/RearStock/AdminMaintenance">
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={tools}
                alt="Maintenance"
              />
            </div>
          </Link>
          <div
            className={classNames(classes.flexItem)}
            onClick={closeApplication}
          >
            <img
              className={classNames(classes.imgProperty)}
              src={exit}
              alt="Exit"
            />
          </div>
        </div>
        <div className={classNames(classes.flexContainer)}>
          <div className={classNames(classes.flexTemText)}>Maintenance</div>
          <div className={classNames(classes.flexTemText)}>Exit</div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminPanelFormControls)
);
