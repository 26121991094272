// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskCheckinLayout.styles';
import {
  KioskWelcomeHeader,
  KioskWelcomeFooter,
  KioskCheckinMain,
  KioskIdelScreen
} from '..';

export class KioskCheckinLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.container)}>
        <KioskWelcomeHeader />
        <KioskCheckinMain />
        <KioskWelcomeFooter customFooterBottom="0%" />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(KioskCheckinLayout);
