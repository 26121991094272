import { connect } from 'react-redux';
import AdminRemoveForm from './AdminRemoveForm';

import * as AdminLoginActions from './AdminRemoveActionCreator';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const mapDispatchToProps = {
  ...AdminLoginActions
};

const AdminRemoveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminRemoveForm);

export default AdminRemoveContainer;
