import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { KIOSKVERIFY_REQUEST } from './KioskConfimPickupActions';

export const kioskConfirmPickup = (data, formActions, context) => ({
  id: uuidv4(),
  datetime: DateTime.local(), // ISO 8601 Format
  type: KIOSKVERIFY_REQUEST,
  context: {
    formActions,
    ...context
  }, // If anything outside of auth needs to be passed to reducer, pass it in context
  payload: {
    ...data
  }
});

export const multipleOrders = (data, formActions, context) => ({
  type: 'MULTIPLE_PICKUP',
  context: {
    formActions,
    ...context
  },
  payload: {
    data
  }
});

export const kioskConfirmUser = (data, formActions) => ({
  id: uuidv4(),
  datetime: DateTime.local(),
  type: 'KIOSK_CONFIRM_USER',
  context: {
    formActions
  },
  payload: {
    ...data
  }
});

export const kioskLocalPatientObj = (data, formActions) => ({
  id: uuidv4(),
  datetime: DateTime.local(),
  type: 'KIOSK_PATIENT_DETAILS',
  context: {
    formActions
  },
  payload: {
    ...data
  }
});

export const kioskConfirmPayment = (data, actions) => ({
  id: uuidv4(),
  datetime: DateTime.local(),
  type: 'KIOSK_PAYMENT_STARTED',
  context: {
    actions
  },
  payload: {
    ...data
  }
});
