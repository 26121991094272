const styles = (theme) => {
  return {
    container: {
      maxWidth: '50vh',
      minHeight: '73vh',
      display: 'grid',
      position: 'relative',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '25px 0px'
    },
    '@media (max-width: 640px)': {
      container: {
        gridTemplateColumns: '320px'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)':
      {
        container: {
          maxWidth: '950px',
          maxHeight: '70vh',
          minHeight: '70vh',
          alignItems: 'normal'
        }
      }
  };
};

export default styles;
