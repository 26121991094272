// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminSettingStockMain.styles';
import { Paper } from '@material-ui/core';

import AdminSettingFormContainer from './AdminSettingFormContainer';

export class AdminSettingMain extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classNames(classes.container)}>
          <AdminSettingFormContainer />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AdminSettingMain);
