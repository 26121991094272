import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminLoginLayout.styles';
import { AdminHeader, AdminFooter, AdminLoginMain } from '..';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  UnameAndPassword: {
    id: 'Admin.Login.UnameAndPassword',
    defineMessages: 'Enter your user name and password.'
  }
});

export class AdminLoginLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle:
      this.props && this.props.intl.formatMessage(messages.UnameAndPassword),
    height: 300
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <AdminHeader myLogoheight={this.state.height} />
        {/* <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        /> */}
        <AdminLoginMain />
        <AdminFooter customFooterBottom="0%" />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminLoginLayout)
);
