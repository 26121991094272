const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      gridGap: '1em',
      justifyContent: 'center',
      alignItems: 'center'
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      minWidth: '45vh',
      margin: '0 auto',
      alignItems: 'center'
    },
    flexItem: {
      display: 'flex',
      width: '13vh',
      height: '13vh',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#4ba686',
      borderRadius: '15px',
      cursor: 'pointer',
      textAlign: 'center'
    },
    ratingContainer: {
      fontWeight: '500',
      color: '#488581',
      display: 'grid',
      width: '100%'
    },
    RemoveMargin: {
      margin: '0!important'
    },
    gridback: {
      background:
        'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)'
    },
    activeBin: {
      background: 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)'
    },
    paper: {
      padding: '10px 0px',
      color: '#000000',
      textAlign: 'center'
    },
    imgProperty: {
      height: 90
    },
    flexTemText: {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '13vh',
      color: '#4ba686',
      fontSize: '1em',
      fontWeight: 500
    },
    buttonContainer: {
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // marginTop: '4%',
      alignItems: 'center',
      position: 'absolute',
      width: '95%',
      left: '2.5%',
      bottom: '1.5%'
    },
    primaryButton: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)'
    },
    selectedButton: {},
    disabledPrimaryButton: {
      marginTop: '1em',
      fontSize: '2em'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '2em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: '#005d67',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      /*border is optional*/
      borderBottom: '1px solid #005d67',
      cursor: 'pointer'
    },
    codeGrid: {
      display: 'grid',
      //
      gridColumnGap: '10px'
    },
    textField: {
      fontSize: '2em'
    },
    kioskDoor: {
      cursor: 'pointer'
    },
    dialogBackground: {
      //background: 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)'
      background: 'transperent'
    },
    dialogContentColor: {
      //color: '#387371',
      color: '#77818D',
      textAlign: 'center',
      fontSize: '1.9em',
      fontWeight: '500'
    },
    alignButton: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonColor: {
      //color: '#387371',
      color: 'black',
      fontSize: '1.9em'
    },
    navContainer: {
      display: 'none'
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)':
      {
        ratingContainer: {
          position: 'absolute',
          width: '500px',
          right: '20px',
          top: '20px',
          zIndex: '999'
        },
        flexContainer: {
          display: 'none'
        },
        navContainer: {
          display: 'flex',
          flexDirection: 'column',
          width: '700px',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          marginTop: '0px'
        },
        navItem: {
          maxWidth: '190px',
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column'
        },
        navItemTop: {
          background: '#4ba686',
          borderRadius: '10px',
          padding: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100px'
        },
        navItemTopImg: {
          width: '40%'
        },
        navItemText: {
          width: '100%',
          color: '#4ba686',
          fontSize: '17px',
          fontWeight: 'bolder',
          display: 'flex',
          justifyContent: 'center'
        },
        paper: {
          padding: '8px 0px'
        },
        backBtn: {
          zIndex: '1000'
        }
      }
  };
};

export default styles;
