import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminSettingLayout.styles';
import {
  AdminHeader,
  AdminFooter,
  AdminSideSlides,
  AdminSettingMain,
  KioskIdelScreen
} from '..';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  Settings: {
    id: 'Admin.Settings.Settings',
    defineMessages: 'Settings'
  }
});

export class AdminSettingLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: this.props && this.props.intl.formatMessage(messages.Settings),
    height: 300
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <AdminHeader myLogoheight={this.state.height} />
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminSettingMain />
        <AdminFooter customFooterBottom="0%" />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminSettingLayout)
);
