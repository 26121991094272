// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminLoginMain.styles';
import { Paper } from '@material-ui/core';

import AdminLoginFormContainer from './AdminLoginFormContainer';

export class AdminLoginMain extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Paper className={classNames(classes.container)}>
          <AdminLoginFormContainer />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AdminLoginMain);
