import React, { useEffect, useState } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  withStyles,
  Typography,
  Button
} from '@material-ui/core';
import { LanguageToggleContainer } from '..';
import Powered_By_Localbox from '../../assets/images/Powered_By_Localbox.png';
import { readSettings } from '../../helpers/board';

const styles = () => ({
  footer__container: {
    height: '6%',
    textAlign: 'center',
    alignItems: 'center'
  },
  needHelp: {
    color: '#939598',
    fontWeight: '500'
  },
  needHelpPhone: {
    color: '#939598',
    fontWeight: '100'
  },
  dialogContent: {
    color: '#77818D',
    textAlign: 'center',
    fontSize: '1.9em',
    fontWeight: '500'
  },
  dialogActions: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    color: 'black',
    fontSize: '1.9em'
  }
});

const messages = defineMessages({
  NeedHelp: {
    id: 'Welcome.NeedHelp',
    defineMessages: 'Need help?'
  },
  DuringBusinessHours: {
    id: 'ExpressUI.DuringBusinessHours',
    defineMessages: 'During business hours'
  },
  AfterHours: {
    id: 'ExpressUI.AfterHours',
    defineMessages: 'After hours'
  },
  CallUs: {
    id: 'Welcome.CallUs',
    defineMessages: 'If you need help, call us at'
  },
  Okay: {
    id: 'Common.Okay',
    defineMessages: 'Okay'
  }
});

const Footer = ({ classes, intl, box, history }) => {
  const [needHelp, setNeedHelp] = useState(false);
  const [accountPhoneNo, setAccountPhoneNo] = useState('...');
  const [afterHourPhone, setAfterHourPhone] = useState('...');

  useEffect(() => {
    let tempMode = false;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      tempMode = !!fileData.dynamicDataSource;
    } else {
      tempMode = !!JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    if (box && box.account) {
      setAccountPhoneNo(
        tempMode
          ? box.account.brand && box.account.brand.phone
          : box.account.contact && box.account.contact.phone
      );
      setAfterHourPhone(
        box.account.brand && box.account.brand.after_hour_phone
      );
    }
  }, [box]);

  return (
    <>
      <Grid
        container
        className={classes.footer__container}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={2}>
          <LanguageToggleContainer />
        </Grid>
        <Grid item xs={8}>
          <Typography
            align="center"
            className={classes.needHelp}
            onClick={(e) => {
              e.stopPropagation();
              setNeedHelp(true);
              e.preventDefault();
            }}
          >
            {intl.formatMessage(messages.NeedHelp)}
          </Typography>
          {accountPhoneNo && (
            <Typography className={classes.needHelpPhone} align="center">
              {intl.formatMessage(messages.DuringBusinessHours)}:{' '}
              {accountPhoneNo}
            </Typography>
          )}
          {afterHourPhone && (
            <Typography className={classes.needHelpPhone} align="center">
              {intl.formatMessage(messages.AfterHours)}: {afterHourPhone}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <img
            src={Powered_By_Localbox}
            alt="Powered_By_Localbox"
            width="90px"
            onClick={() => history.push('/SecureBadgeLogin')}
          />
        </Grid>
      </Grid>
      <Dialog
        open={needHelp}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={'lg'}
        fullWidth={true}
        PaperProps={{
          style: { borderRadius: 0 }
        }}
      >
        <div
          style={{
            background: 'transperent'
          }}
        >
          <DialogContent className={classes.dialogContent}>
            {intl.formatMessage(messages.CallUs)} {accountPhoneNo}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={() => setNeedHelp(false)}
              className={classes.button}
            >
              {intl.formatMessage(messages.Okay)}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(injectIntl(Footer));
