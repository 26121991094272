export default {
  step0: 0, // Touch to Start
  step1: 1, //Pick up Code Enter
  step2: 2, //DOB verification
  step3: 3, //Confirm Pick up items
  step4: 4, //Consult
  step5: 5, //Signature
  step6: 6, //DL Capture(who's picking up)
  step7: 7, //Collect your items (Bin open)
  step8: 8, //Survey /Feedback /ratting
  step9: 9, //Confirm Pickup Payment,
  step10: 10 //View Current Rx for patient login
};
