// @flow
// This polyfill inclusion must be the first line in src/index.js
import 'react-app-polyfill/ie9';
import { loadComponents } from 'loadable-components';
import React from 'react';
import { hydrate, render } from 'react-dom';
import AppWrapper from './AppWrapper';
import { init } from './helpers/board';
import {
  connectRouter,
  history,
  Provider,
  rootReducer,
  Router,
  store
} from './helpers/redux';
import * as serviceWorker from './helpers/serviceWorker';
import './index.css';
import { toast } from 'react-toastify';
import './toastify.css';
import 'react-simple-keyboard/build/css/index.css';

toast.configure({
  draggable: false,
  position: 'top-center',
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true
});

if (window.electron) {
  const { webFrame } = window.electron;
  // es-lint-disable-next-line
  if (webFrame) {
    if (window.screen.width === 1280 && window.screen.height === 800) {
      webFrame.setZoomFactor(1);
    } else {
      webFrame.setZoomFactor(1.5);
    }
  }
}

init();

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  loadComponents().then(() => {
    hydrate(
      // This redundant pattern is intentional, for hot reload
      <Provider store={store}>
        <Router history={history}>
          <AppWrapper store={store} history={history} />
        </Router>
      </Provider>,
      rootElement
    );
  });
} else {
  if (module.hot) {
    module.hot.accept('./AppWrapper', () => {
      render(
        // This redundant pattern is intentional, for hot reload
        <Provider store={store}>
          <Router history={history}>
            <AppWrapper store={store} history={history} />
          </Router>
        </Provider>,
        rootElement
      );
    });

    module.hot.accept('./reducers', () => {
      store.replaceReducer(connectRouter(history)(rootReducer));
    });
  }
  render(
    // This redundant pattern is intentional, for hot reload
    <Provider store={store}>
      <Router history={history}>
        <AppWrapper store={store} history={history} />
      </Router>
    </Provider>,
    rootElement
  );
}

window.addEventListener('load', function () {
  [false, 'false'].includes(localStorage.getItem('isKioskOnline')) &&
    toast.success(
      `Uh oh, I've lost the internet. While I look for it, some functions may be limited.`,
      {
        autoClose: false,
        toastId: 'toaster'
      }
    );

  window.addEventListener('online', async function (event) {
    if (
      [false, 'false'].includes(localStorage.getItem('isKioskOnline') || false)
    ) {
      localStorage.setItem('isKioskOnline', true);
      toast.dismiss();
      toast.info('You are now back online.', {
        delay: 500,
        autoClose: 4000,
        toastId: 'toaster'
      });
    }
  });

  window.addEventListener('offline', function (event) {
    if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
      localStorage.setItem('isKioskOnline', false);
      toast.success(
        `Uh oh, I've lost the internet. While I look for it, some functions may be limited.`,
        {
          autoClose: false,
          toastId: 'toaster'
        }
      );
    }
  });
});

if (process.env.NODE_ENV === 'development') {
  //serviceWorker.unregister();
  serviceWorker.register({});
  process.env.REACT_APP_REDUX_DEVTOOLS_EXTENSION === 'true' &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__.open();
} else if (process.env.NODE_ENV === 'production') {
  serviceWorker.register({});
}
