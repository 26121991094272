// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminFooter.styles';
import footerlogo from './AdminImages/footerlogo.png';
import { withRouter } from 'react-router';

export class AdminFooter extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  handleBack = () => {
    this.props.history.push('/RearStock/AdminLogin');
  };

  render() {
    const { classes, customFooterBottom } = this.props;

    return (
      <div
        style={{ bottom: customFooterBottom }}
        className={classNames(classes.root)}
      >
        <div className={classNames(classes.Admin, classes.backKiosk)}>
          <label className={classNames(classes.labelClass)}>
            <span
              onClick={this.handleBack}
              className={classNames(classes.spanLink)}
            >
              Back
            </span>
          </label>
        </div>
        <div className={classNames(classes.space)}>&nbsp;</div>
        <div className={classNames(classes.space)}>&nbsp;</div>
        <div className={classNames(classes.Admin, classes.backIRS)}>
          <label className={classNames(classes.labelClass)}>
            <span
              onClick={this.handleBack}
              className={classNames(classes.spanLink)}
            >
              Back
            </span>
          </label>
        </div>
        <div className={classNames(classes.poweredBy)}>
          <img alt="logo" src={footerlogo} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(AdminFooter));
