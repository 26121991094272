import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import styles from './ContactlessPickupForm.styles';
import { injectIntl, defineMessages } from 'react-intl';
import { withRouter } from 'react-router';
import Webcam from 'react-webcam';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { connect } from 'react-redux';
import * as kioskActions from '../Kiosk/KioskBackgroundSliderActionCreator';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { readSettings } from '../../helpers/board.js';
const { syncPatientLifBoatWithCould } = require('../Common/SyncAPIs');

const messages = defineMessages({
  PickupSmileTitle: {
    id: 'Verify.PickupSmileTitle',
    defaultMessage: 'Contactless pick up makes you smile!'
  },
  Second: {
    id: 'Verify.Second',
    defaultMessage: 'Seconds'
  }
});

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})((props) => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

export class ContactlessPickupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHideTimer: true
    };
  }

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      this.setState({ isHideTimer: false });
      this.capture();
      setTimeout(() => {
        this.props.history.push('/KioskCollectMedicines');
        this.props.history.push('/KioskCollectMedicines');
      }, 500);
    }
    return (
      <div>
        <div>{remainingTime}</div>
        <div>
          {this.props && this.props.intl.formatMessage(messages.Second)}{' '}
        </div>
      </div>
    );
  };

  capture = () => {
    setTimeout(() => {
      const imageSrc = this.webcam.getScreenshot();
      var patient = this.props.localPatientObj;
      patient = patient ? patient : {};
      patient.picture_url = imageSrc;
      this.props.kioskLocalPatientObj(patient);
      let _localPatientObj = this.props.localPatientObj;
      let formData = {
        CustomerID: _localPatientObj.id,
        OrderID:
          _localPatientObj &&
          _localPatientObj.Orders &&
          _localPatientObj.Orders[0] &&
          _localPatientObj.Orders[0].id,
        FirstName: _localPatientObj.first_name,
        LastName: _localPatientObj.last_name,
        dateOfBirth: _localPatientObj.dob_str,
        DriversLicenseNo: '',
        value: '',
        selectedStateCode: '',
        dlrawdata: '',
        kioskBoxAccountConnection:
          this.props &&
          this.props.box &&
          this.props.box.account &&
          this.props.box.account.account_connections_id,
        isConsultRequested: _localPatientObj
          ? _localPatientObj.is_consult_requested
          : false,
        picture_url: _localPatientObj ? _localPatientObj.picture_url : '',
        signature_url: _localPatientObj ? _localPatientObj.signature_url : '',
        BoxID: this.props && this.props.box && this.props.box.id,
        AccountID:
          this.props && this.props.box && this.props.box.parent_account_id
      };
      let mode;
      if (window.electron) {
        const fileData = JSON.parse(readSettings());
        mode = fileData ? fileData.dynamicDataSource : false;
      } else {
        mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
      }
      if (mode === true || mode === 'true') {
        syncPatientLifBoatWithCould(formData);
      }
      let data = {
        url: process.env.REACT_APP_BASEURL + 'saveKioskCustomerCollectedDetail',
        type: 'POST',
        name: 'saveKioskCustomer',
        body: {
          formData
        }
      };
      this.props.kioskConfirmUser(data);
    }, 30);
  };

  render() {
    const { classes } = this.props;

    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user'
    };
    var webHeight, webWidth;

    if (window.screen.width === 1440) {
      webHeight = '100%';
      webWidth = '100%';
    } else {
      webHeight = '340px';
      webWidth = '600px';
    }

    return (
      <div>
        <div className={classes.container}>
          <div className={classes.title}>
            <span style={{ color: ' #2F5597', fontSize: '1.5em' }}>
              {this.props &&
                this.props.intl.formatMessage(messages.PickupSmileTitle)}
            </span>
          </div>
          <div className={classes.WebCamContainer}>
            <Webcam
              id="vid"
              audio={false}
              height={webHeight}
              ref={this.setRef}
              screenshotFormat="image/png"
              width={webWidth}
              videoConstraints={videoConstraints}
              style={{ border: '2px solid  #2F5597' }}
            />
          </div>
          <div className={classes.TimerWrapper}>
            {this.state.isHideTimer ? (
              <CountdownCircleTimer
                isPlaying
                duration={5}
                colors={[['#004777', 0.33], ['#F7B801', 0.33], ['#A30000']]}
                onComplete={() => [true, 1000]}
              >
                {this.renderTime}
              </CountdownCircleTimer>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...kioskActions
};

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return {
    ...kiosk
  };
};
const KioskVerify = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactlessPickupForm);

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(KioskVerify))
);
