import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminPanelStockLayout.styles';
import {
  AdminHeader,
  AdminFooter,
  AdminSideSlides,
  AdminPanelStockMain,
  KioskIdelScreen
} from '..';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  ScanStockCode: {
    id: 'Admin.Stock.ScanStockCode',
    defineMessages: 'Scan the stock code to begin.'
  }
});

export class AdminPanelStockLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle:
      this.props && this.props.intl.formatMessage(messages.ScanStockCode),
    height: 300
  };

  render() {
    const { classes } = this.props;
    const isRemoveIdelTimerEnabled = localStorage.getItem('removeIdelTimer');
    return (
      <div className={classNames(classes.container)}>
        <AdminHeader myLogoheight={this.state.height} />
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminPanelStockMain />
        <AdminFooter customFooterBottom="0%" />
        {isRemoveIdelTimerEnabled === 'false' ? <KioskIdelScreen /> : null}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminPanelStockLayout)
);
