import { Map } from 'immutable';

const locale = (state = Map({ lang: 'en' }), action) => {
  switch (action.type) {
    case 'LOCALE/CHANGED':
      return Map({ lang: action.payload.data });
    default:
      return state;
  }
};

export default locale;
